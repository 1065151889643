'use strict';
/**
 * The job list
 * @param {Object} $scope - the local scope object
 * @param {Object} $state - the state object
 * @param {Object} DataManager - the DataManager API
 * @param {Object} Notification - The Notification util
 */
angular.module('cpformplastApp.jobs')
    .controller('newJobsController', function($scope, $state, DataManager, Notification, $modal) {

  $scope.tableButtons = [{
    'title':'Visuelle',
    'class': 'primary',
    'function': () => {
      $scope.machineType = "manual";
      $scope.filterSubjobs();
      $scope.tableButtons[0].class = 'primary';
      $scope.tableButtons[1].class = 'default';
    }
  },
  {
    'title':'Automatic',
    'class': 'default',
    'function': () => {
      $scope.machineType = "automatic";
      $scope.filterSubjobs();
      $scope.tableButtons[0].class = 'default';
      $scope.tableButtons[1].class = 'primary';
    }
  }];

  $scope.tableStructure = [
    {
      value: "title",
      title: "# JOB",
      filter:'alpha'
    },
    {
      value: "mold",
      title: "# MOULE",
      filter:'alpha'
    },
    {
      value: "setup",
      title: "SETUP",
      filter:'alpha'
    },
    {
      value: "client",
      title: "CLIENT",
      filter:'alpha'
    },
    {
      value: "item",
      title: "MOULE",
      filter:'alpha'
    },
    {
      value: "hoursAmount",
      title: "NB HEURES",
      filter:'num'
    },
    {
      value: "ups",
      title: "UPS",
      filter:'num'
    },
    {
      value: "material",
      title: "MATÉRIEL",
      filter:'alpha'
    },
    {
      value: "remainingQuantity",
      title: "QTÉ.",
      filter:'num'
    },
    {
      value: "materialDeliveryDate",
      title: "MAT. LIVR.",
      filter: "date"
    },
    {
      value: "deliveryDate",
      title: "LIVR.",
      filter:'date'
    },
    {
      value: "sheetsPerHour",
      title: "FLS/HR",
      filter:'num'
    },
    {
      value: "salesman",
      title: "VENDEUR",
      filter:'alpha'
    }
  ];

  $scope.subjobs = [];
  $scope.displayedSubjobs = [];
  $scope.machineType = "manual";

  /**
   * Initialisation function
   */

  $scope.init = () => {
    $scope.fetchSubjobs();
    $scope.setSubjobsSeen();
  };

  $scope.fetchSubjobs = () => {
    DataManager.fetchUnplannedSubjobs()
    .then(subjobs => {
      $scope.subjobs = subjobs.map(s => s.tableStruct);
      $scope.filterSubjobs();
    })
    .catch(err => {
      console.log(err);
      Notification.error('Un problème est survenu lors du chargement des jobs');
    });
  };

  $scope.setSubjobsSeen = () => {
    DataManager.setSubjobsSeen();
  };

  $scope.filterSubjobs = () => {
    $scope.displayedSubjobs = $scope.subjobs.filter(s => s.machineType === $scope.machineType);
  };

  $scope.selectSubjob = (subjob) => {
    var modalInstance = $modal.open({
      animation: true,
      templateUrl: '../../components/modal/subjob/assign-machine-modal.html',
      controller: 'AssignMachineModal',
      resolve:{
        subjob: () => subjob
      }
    });
    modalInstance.result.then(null, function() {
      $scope.fetchSubjobs();
    });
  };

  $scope.init();

});
